// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CloudRunPathBuilder, Env, IEmployerEnvironment } from 'shared-lib'

const env: Env = 'dev'
//const pathBuilder = new AppEnginePathBuilder(env)
const pathBuilder = new CloudRunPathBuilder('dr32hxly6a')

export const environment: IEmployerEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyCnP-U4Q9cCTJ6jCB5aAfdNdPm66Owq6_4',
    authDomain: 'cnect-dev.firebaseapp.com',
    databaseURL: 'https://cnect-dev.firebaseio.com',
    projectId: 'cnect-dev',
    storageBucket: 'cnect-dev.appspot.com',
    messagingSenderId: '114692117909',
    appId: '1:114692117909:web:0d34f54fe37dd08d74129c',
    measurementId: 'G-3XTZGN7JT3',
  },

  services: {
    cloudFunctions: 'https://us-central1-cnect-dev.cloudfunctions.net',
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    events: pathBuilder.build('events'),
    applicationHistory: pathBuilder.build('applicationHistory'),
    user: pathBuilder.build('user'),
    content: pathBuilder.build('content'),
    employer: pathBuilder.build('employer'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    messaging: pathBuilder.build('messaging'),
    virtualDialogue: pathBuilder.build('virtualDialogue'),
    access: pathBuilder.build('access'),
    jobsIntegration: pathBuilder.build('jobsIntegration'),
    notifications: pathBuilder.build('notifications'),
    customer: pathBuilder.build('customer'),
    jobsAutomation: pathBuilder.build('jobsAutomation'),
    candidatePool: pathBuilder.build('candidatePool'),
    sse: pathBuilder.build('sse', false),
    product: pathBuilder.build('product'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcVas0aAAAAAAcHaLi9835I5X1EPovMGnsTVm_Z',
  tenantId: 'B2B-USER-z43gz',
  algoliaAppId: 'SH1DAV8W6D',
  cdnCname: 'dev-content.cnected.com',
  basePlatformHost: 'dev-employer.cnected.com',
  candidateExternalJobUrl: 'http://localhost:4600/#/landing/job/',
  candidateEmailActionRoute: 'http://localhost:4600/#/auth/actions',
  careersPageBaseLink: 'https://dev-careers.cnected.com/',
  employerBaseUrl: 'http://localhost:4610/',
  employerEmailSuccessRoute: 'http://localhost:4100/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'sfIqysHX4k910Rq0K4r3',
  emailVerificationEmailTemplateId: '5KYG0zYnsdtR0DLtgjwv',
  betaMessageId: '1HV5MRdznzPO0ivgBbom',
  version: '3c12412',

  tenant: {
    appConfigBaseURL: 'https://dev-content.cnected.com/assets/bootstrap',
    defaultTenant: 'dev-employer.cnected.com',
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
